.main-container {
  margin: 0 auto;
  padding: 10px 1px 5px 5px;
}

.info-container {
  height: 344px;
  width: 410px;
  margin: 0 auto;
  background-color: #8bc6ec;
  background-image: linear-gradient(135deg, #8bc6ec 0%, #9599e2 100%);
  border-radius: 15px;
  padding: 10px 0;
}

.profile-img {
  height: 116px;
  width: 120px;
  padding: 10px;
  margin: 10px auto;
  border-radius: 50%;
  margin-top: 20px;
  margin-left: 145px;
}

.name {
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: 600;
}

.bio {
  text-align: center;
  color: white;
  font-size: 14px;
  margin-top: -12px;
}

.icon-container {
  display: flex;
  color: white;
  gap: 40px;
  margin-left: 100px;
}

.call-contain {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #b6cbff;
  margin-bottom: 10px;
}

.info-contain {
  height: 168px;
  width: 395px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 0px 1px, rgba(0, 0, 0, 0.09) 0px 0px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 0px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
  margin: 0 auto;
  border-radius: 15px;
  margin-top: 70px;
}

.text-bio {
  margin: 0 40px;
  padding-top: 40px;
  text-align: justify;
  color: #220e27;
}

.social-container {
  height: 483px;
  width: 410px;
  background-color: #f3f3f3;
  margin: 50px auto;
  border-radius: 15px;
}

.mobnum {
  font-size: 15px;
  color: #220e27;
  font-weight: 600;
  padding-top: 30px;
  margin-left: 25px;
}

.mail {
  font-size: 15px;
  color: #220e27;
  font-weight: 600;
  margin-left: 25px;
  padding-top: 10px;
}

.reltext {
  color: #8b8ca5;
  font-size: 13px;
  margin-left: 70px;
}

.map {
  color: #759dfe;
  font-size: 13px;
  margin-left: 70px;
  cursor: pointer;
}

.findme {
  text-align: center;
  color: #220e27;
  font-size: 16px;
}

.appcontain {
  height: 145px;
  width: 410px;
  border-radius: 15px;
  background-color: #f3f3f3;
  margin: 20px auto;
  padding: 20px;
}

.icon {
  height: 40px;
  border-radius: 50%;
  margin: 15px auto;
  display: block;
}

.icon-name {
  text-align: center;
  color: #220e27;
  font-weight: 500;
  font-size: 15px;
}

.btn {
  display: block;
  margin: 30px auto;
  border: none;
  background-color: #6dbbe8;
  color: white;
  font-size: 18px;
  font-weight: bold;
  height: 52px;
  width: 252px;
  border-radius: 50px;
}

@media only screen and (max-width: 768px) {
  .social-container,
  .appcontain {
    width: 90%;
  }

  .profile-img {
    margin-left: auto;
    margin-right: auto;
  }

  .icon-container {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .info-contain {
    height: 168px;
    width: 90%;
    box-shadow: none;
    border: 1.5px solid black;
    margin: 0 auto;
    border-radius: 15px;
    margin-top: 50px;
  }
}
